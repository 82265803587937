
import { defineComponent, onMounted, PropType, ref } from 'vue';
import {
  Chart,
  ChartData,
  ChartTypeRegistry,
  registerables,
  Tooltip
} from 'chart.js';
import { Student } from '../../models';

Chart.register(...registerables);

export default defineComponent({
  props: {
    chartData: {
      type: Object as PropType<ChartData>,
      required: true
    },
    student: {
      type: Object as PropType<Student>,
      required: true
    }
  },
  setup(props) {
    const graduationChart = ref(null);

    onMounted(async () => {
      if (graduationChart.value !== null) {
        const canvas: any = graduationChart.value;
        if (!(canvas instanceof HTMLCanvasElement)) {
          return;
        }
        if (!canvas || !canvas.getContext) return;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) return;

        Tooltip.positioners.positionAbove = function (elements, position) {
          var offset = 10;
          return {
            x: position.x,
            y: position.y - offset
          };
        };

        const chartAreaBorder = {
          id: 'chartAreaBorder',
          beforeDraw(
            chart: Chart<keyof ChartTypeRegistry, (number | null)[]>,
            args: { cancelable: true },
            options: { borderColor: string; borderWidth: number }
          ) {
            const {
              ctx,
              chartArea: { left, top, width, height }
            } = chart;
            ctx.save();
            ctx.strokeStyle = options.borderColor;
            ctx.lineWidth = options.borderWidth;
            ctx.beginPath();
            ctx.moveTo(left, top + height);
            ctx.lineTo(left + width, top + height);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(left, top);
            ctx.lineTo(left, top + height);
            ctx.stroke();
            ctx.restore();
          }
        };

        new Chart(ctx, {
          type: 'line',

          data: props.chartData,
          options: {
            animation: {
              duration: 500
            },
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 0
              },
              line: {
                borderWidth: 3
              }
            },
            scales: {
              y: {
                min: 0,
                max: 100,
                display: true,
                grid: {
                  display: false
                }
              },
              x: {
                display: false,
                grid: {
                  display: false
                }
              }
            },
            plugins: {
              chartAreaBorder: {
                borderColor: 'rgb(57 57 69)',
                borderWidth: 2
              },
              tooltip: {
                filter: (e, index, array, data) => {
                  return data.datasets[e.datasetIndex].label === 'student';
                },
                displayColors: false,
                enabled: true,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                position: 'positionAbove',
                backgroundColor: 'rgb(57 57 69)',
                yAlign: 'bottom',
                callbacks: {
                  label: (chartInfo) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (chartInfo.dataset.currentRank) {
                      return (
                        'Graduation Probability: ' +
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        chartInfo.dataset.currentRank +
                        '%'
                      );
                    } else {
                      return 'No current rank information';
                    }
                  }
                }
              },
              title: {
                display: false
              },
              legend: {
                display: false
              }
            }
          },
          plugins: [chartAreaBorder]
        });
      }
    });
    return {
      graduationChart
    };
  }
});
