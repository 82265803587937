import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "graduationCanvas",
  ref: "graduationChart",
  key: "props.range"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("canvas", _hoisted_1, null, 512))
}