const pointPhotoStyle = (photoUrl: string): HTMLCanvasElement => {
  const pointCanvas = document.createElement('canvas');
  const ctx = pointCanvas.getContext('2d');

  const userPhotoPoint = new Image();
  userPhotoPoint.src = photoUrl;

  userPhotoPoint.onload = function () {
    if (ctx) {
      ctx.beginPath();
      ctx.arc(
        pointCanvas.width / 2,
        pointCanvas.height / 2,
        25,
        0,
        Math.PI * 2,
        true
      );
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(
        userPhotoPoint,
        pointCanvas.width / 2 - 25,
        pointCanvas.height / 2 - 25,
        50,
        50
      );
      ctx.beginPath();
      ctx.arc(
        pointCanvas.width / 2 - 25,
        pointCanvas.height / 2 - 25,
        25,
        0,
        Math.PI * 2,
        true
      );
      ctx.clip();
      ctx.closePath();
    }
  };
  return pointCanvas;
};

const pointLabelStyle = (label: string, color: string): HTMLCanvasElement => {
  const pointCanvas = document.createElement('canvas');
  const ctx = pointCanvas.getContext('2d');

  if (ctx) {
    const endOfLinePosition = {
      x: pointCanvas.width / 2,
      y: pointCanvas.height / 2
    };

    // Point Styles
    const pointMarginLeft = 30;
    const pointSizeX = 24;
    const pointSizeY = 6;
    const pointBorderRadius = 3;
    const pointPositionX = endOfLinePosition.x + pointMarginLeft;
    const pointPositionY = endOfLinePosition.y - pointSizeY / 2;

    // Label Styles
    const wordWidth = ctx.measureText(label).width / 2;
    const labelMarginLeft = 14;
    const labelPositionX = pointPositionX + labelMarginLeft + wordWidth;
    const labelPositionY = endOfLinePosition.y + 5;
    const labelFont = '16px Arial';
    const labelColor = '#fff';

    // Draw Point
    ctx.beginPath();
    roundRect(
      ctx,
      pointPositionX,
      pointPositionY,
      pointSizeX,
      pointSizeY,
      pointBorderRadius,
      true,
      false
    );
    ctx.fillStyle = color;
    ctx.fill();
    ctx.closePath();

    // Draw Label
    ctx.font = labelFont;
    ctx.fillStyle = labelColor;
    ctx.fillText(label, labelPositionX, labelPositionY);
  }

  return pointCanvas;
};

const roundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: { tl: number; tr: number; br: number; bl: number } | number,
  fill: boolean,
  stroke: boolean
) => {
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

const setLastPointRadius = (dataSet: number[]): number[] =>
  dataSet.map((point, index) => {
    if (index === dataSet.length - 1) {
      return 10;
    }
    return 0;
  });

const setLastPointStyle = (
  pointStyle: HTMLCanvasElement,
  dataSet: number[]
): unknown[] =>
  dataSet.map((point, index) => {
    if (index === dataSet.length - 1) {
      return pointStyle;
    }
    return '';
  });

const colorPicker = (
  index: number
): { borderColor: string; legendLabel: string } => {
  const fighterStyle = 'rgb(52, 211, 153)';
  const mobilityStyle = 'rgb(129, 140, 248)';
  const heloStyle = 'rgb(252, 165, 165)';
  const otherStyle = 'rgb(220, 38, 38)';
  switch (index) {
    case 0:
      return {
        borderColor: fighterStyle,
        legendLabel: 'Fighter'
      };
    case 1:
      return {
        borderColor: mobilityStyle,
        legendLabel: 'Mobility'
      };
    case 2:
      return {
        borderColor: heloStyle,
        legendLabel: 'Helo'
      };
    default:
      return {
        borderColor: otherStyle,
        legendLabel: 'Other'
      };
  }
};

export {
  pointPhotoStyle,
  pointLabelStyle,
  setLastPointRadius,
  setLastPointStyle,
  colorPicker
};
