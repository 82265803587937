
import { computed, defineComponent, PropType, ref } from 'vue';
import Routes from '@/router/Routes';
import GraduationChart from '@/components/charts/GraduationChart.vue';
import ZeroLandingComponent from '@/components/ZeroLandingComponent.vue';
import {
  colorPicker,
  pointPhotoStyle,
  setLastPointRadius,
  setLastPointStyle
} from './GraduationProbabilityChartStyles';
import { Student } from '../../models';

export default defineComponent({
  components: {
    GraduationChart,
    ZeroLandingComponent
  },

  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    }
  },

  setup(props) {
    const followOnToColor = (followOn: string): string => {
      const followOnToColorMap: { [key: string]: string } = {
        'T-1': 'rgb(129, 140, 248)',
        'T-38': 'rgb(52, 211, 153)',
        SUPTH: 'rgb(252, 165, 165)',
        other: 'rgb(220, 38, 38)'
      };
      let returnColor = 'rgb(220, 38, 38)';
      Object.keys(followOnToColorMap).forEach((key) => {
        if (followOn.includes(key)) {
          returnColor = followOnToColorMap[key];
        }
      });
      return returnColor;
    };

    const studentHistoricalData = ref(props.student.historicalData);

    const hasStudentHistoricalData =
      studentHistoricalData.value && studentHistoricalData.value.datasets;

    const trimData = (dataset: number[], trimLength: number) => {
      if (dataset.length < trimLength) {
        return dataset;
      }
      return dataset.filter((_, index) => {
        const datasetLength = dataset.length;
        const divisor = Math.round(datasetLength / trimLength);
        return index % divisor == 0;
      });
    };

    let chartData;
    let neighborData;

    if (hasStudentHistoricalData) {
      const relativeRankCourse = computed(() => {
        if (
          props.student.historicalData &&
          props.student.historicalData.rank_course
        ) {
          const returnValue = props.student.historicalData.rank_course.map(
            (data) => {
              if (data.id === props.student.id) {
                return {
                  class_rank: '',
                  follow_on_course: ''
                };
              }
              if (!data.class_rank) {
                return {
                  studentId: data.id,
                  follow_on_course: data.follow_on_course,
                  lineColor: followOnToColor(data.follow_on_course)
                };
              }

              return {
                studentId: data.id,
                follow_on_course: data.follow_on_course,
                lineColor: followOnToColor(data.follow_on_course)
              };
            }
          );
          return returnValue;
        }
        return [];
      });

      const studentDataLength = 25;
      const neighborDataLength = 50;

      const studentDataSetTrimmed = trimData(
        studentHistoricalData.value.datasets[0].data,
        studentDataLength
      );

      const offset = 50;
      const studentDataOffset = studentDataSetTrimmed.map(
        (x: number) => x + offset
      );

      const studentData = {
        label: 'student',
        data: studentDataOffset,
        pointRadius: setLastPointRadius(studentDataSetTrimmed),
        pointStyle: setLastPointStyle(
          pointPhotoStyle(props.student.photoUrl),
          studentDataSetTrimmed
        ),
        pointHitRadius: 25,
        borderColor: '#fff',
        borderDash: [5, 5],
        currentRank: Math.round(studentDataOffset[studentDataOffset.length - 1])
      };

      neighborData = studentHistoricalData.value.datasets
        .filter((dataset: any) => dataset.label !== props.student.id)
        .map((dataset: any) => {
          const borderColorFind = relativeRankCourse.value.find(
            (element) => element.studentId === dataset.label
          );
          const borderColor = borderColorFind
            ? borderColorFind.lineColor
            : 'rgb(220, 38, 38)';

          return {
            data: trimData(
              dataset.data.map((x: number) => x + offset),
              neighborDataLength
            ),
            borderColor
          };
        });

      chartData = {
        labels: neighborData[0].data.map(() => ''),
        datasets: [studentData, ...neighborData]
      };
    }

    // normal values are from -50 to 50, we want to scale them to 0 to 100

    return {
      hasStudentHistoricalData,
      chartData,
      Routes,
      neighborData
    };
  }
});
